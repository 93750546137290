<script lang="ts">
import { useConfigStore } from '~/store'

useHead({
    titleTemplate: 'Pildom ADMIN - %s',
})

export default {
    name: 'DefaultLayout',
    data: () => ({
    }),
    computed: {
        currentPage() {
            return this.$currentPath(this.$route.matched[0].path).pop()
            // return this.$refs && this.$refs.MenuBreadcrumbs && this.$refs.MenuBreadcrumbs.breadcrumbItems.slice(-1)
        },
        loading() {
            return useConfigStore()?.globalLoading
        },
        maintenance() {
            return useConfigStore()?.maintenance
        },

    },
    created() {
    // useConfigStore().setGlobalLoading(false)
    },
    mounted() {
        useConfigStore().setGlobalLoading(false)
    },
    methods: {
        showTitle(path) {
            path = path.replaceAll('/', '.')
            return path
        },
    },
}
</script>

<template>
    <v-layout>
        <MenuGlobalLoader :loading="loading">
            <template #loader>
                <svg viewBox="25 25 50 50">
                    <circle
                        class="loader-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#636363"
                        stroke-width="2"
                    />
                </svg>
            </template>
            <template #logo>
                <!--                        <img -->
                <!--                            class="mx-auto" -->
                <!--                            src="@/assets/images/logo-cleaned.png" -->
                <!--                            alt="Pildom logo" -->
                <!--                        > -->
            </template>
        </MenuGlobalLoader>

        <MenuMaintenanceMessage>
            <template #logo>
                <!--                        <img -->
                <!--                            class="mx-auto" -->
                <!--                            src="@/assets/images/logo-cleaned.png" -->
                <!--                            alt="Pildom logo" -->
                <!--                        > -->
            </template>
        </MenuMaintenanceMessage>

        <client-only>
            <!-- <MenuNavigationDrawer
                v-if="!maintenance"
                ref="MenuNavigationDrawer"
            >
                <template #menu="{ rail }">
                    <MenuNavigationSidebar
                        :rail="rail"
                    />
                </template>
            </MenuNavigationDrawer> -->
            <!-- <GlobalAlert /> -->

            <v-main
                v-if="!maintenance && !loading"
                id="main-content"
                class="bg-custom-light"
            >
                <header>
                    <MenuAppBar
                        @show-navigation="$refs.MenuNavigationDrawer?.showNavigation()"
                    >
                        <template #title>
                            <v-row align="center">
                                <v-col md="3" lg="2" :class="$vuetify.display.mdAndUp && 'text-center '">
                                    <img
                                        class="w-auto"
                                        src="@nuxtcommon/assets/images/logo.png"
                                        alt="Pildom logo"
                                        height="60"
                                    >
                                </v-col>
                                <v-col class="d-none d-lg-block">
                                    Pildom
                                    <br>
                                    <div class="text-caption">
                                        Portail pour la gestion du domaine public
                                    </div>
                                </v-col>
                            </v-row>
                        </template>
                        <template #rightMenu>
                            <div v-if="$vuetify.display.smAndUp">
                                <MenuAppbarUserProfile login-color="light" title-connexion-modal="Connexion à l'administration PILDOM" />
                            </div>
                        </template>
                    </MenuAppBar>

                    <MenuNavigationDrawer
                        v-if="!maintenance"
                        ref="MenuNavigationDrawer"
                    >
                        <template #menu="{ rail }">
                            <MenuNavigationSidebar
                                :no-logo="true"
                                :rail="rail"
                            />
                            <div v-if="!$vuetify.display.smAndUp">
                                <MenuAppbarUserProfile login-color="light" title-connexion-modal="Connexion à l'administration PILDOM" />
                            </div>
                        </template>
                    </MenuNavigationDrawer>
                </header>

                <div
                    id="page-content"
                    class="pa-6"
                >
                    <div id="page-header" class="mb-4">
                        <Breadcrumbs />
                        <h1
                            v-if="currentPage"
                            class="text-h4"
                        >
                            {{ $translate(currentPage.title) }}
                        </h1>
                        <MenuGlobalAlert />
                    </div>
                    <slot /> <!-- OUTPUT PAGE -->
                    <Toaster />
                </div>
                <MenuFooter />
            </v-main>
        </client-only>
    </v-layout>
</template>
